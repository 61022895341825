<script>
export default {
  inheritAttrs: false,
  data() {
    return {
      errorMessage: "",
      loading: false,
      notifications: [],
      notificationsUnreadCount: 0,
      openMenu: false,
      page: 1,
      lastPage: 1,
    };
  },
  computed: {
    hasNextPage() {
      return this.page < this.lastPage;
    },
    resource() {
      return this.apiResource("/v1/authorization/notificacoes");
    },
  },
  methods: {
    async getNotifications() {
      try {
        this.errorMessage = "";
        this.loading = true;
        const response = await this.resource.get({
          query: `page=${this.page}&per_page=200`,
        });

        if (Array.isArray(response.data)) {
          this.notifications.push(...response.data);
        }

        if (response.meta) {
          this.notificationsUnreadCount =
            response.meta.notifications_unread_count ?? 0;
          this.lastPage = response.meta.lastPage ?? 1;
        }
      } catch (error) {
        this.errorMessage = this.errorHandler(error);
      } finally {
        this.loading = false;
      }
    },
    loadMore() {
      this.page += 1;
      this.getNotifications();
    },
    async markAsRead(id, read = false) {
      try {
        if (read) {
          return;
        }

        const response = await this.resource.save({}, id);
        const index = this.notifications.findIndex((note) => note.id === id);

        if (index >= 0) {
          this.notifications.splice(index, 1, response);
          this.notificationsUnreadCount -= 1;
        }
      } catch (error) {
        this.errorMessage = this.errorHandler(error);
      }
    },
  },
  created() {
    this.getNotifications();
  },
};
</script>

<template>
  <v-menu v-model="openMenu" :nudge-width="250" offset-y>
    <template v-slot:activator="{ on }">
      <v-badge
        color="error"
        :content="notificationsUnreadCount"
        :value="notificationsUnreadCount"
        overlap
        v-bind="$attrs"
      >
        <v-btn class="bell-btn" color="#FFF" icon v-on="on">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-alert
      v-if="errorMessage"
      class="error-alert"
      dense
      text
      type="error"
    >
      {{errorMessage}}
    </v-alert>
    <div v-if="!notifications.length" class="no-data-wrapper">
      <span>Nenhuma notificação encontrada.</span>
    </div>
    <v-list v-else dense max-width="400">
      <v-list-item v-for="{ id, message, read } in notifications" :key="id">
        <v-list-item-content :class="{ 'unread-message': !read }" @click="markAsRead(id, read)">
          <v-badge color="error" dot offset-x="10" overlap :value="!read">
            <p class="notification-message">{{ message }}</p>
          </v-badge>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-if="hasNextPage">
        <v-list-item-content>
          <v-btn @click="loadMore()">Carregar mais</v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<style scoped lang="scss">
.bell-btn {
  stroke: #fff;
  z-index: 1;

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }

  .loading-animation {
    animation: rotating 1s linear infinite;
  }
}

.error-alert {
  font-size: 0.75rem;
  margin: 0.5rem;
}

.notification-message {
  margin-bottom: 0;
  width: calc(100% - 10px);
}

.no-data-wrapper {
  padding: 0.75rem;
  text-align: center;
}

.unread-message {
  cursor: pointer;
}

.v-badge::v-deep .v-badge__badge {
  z-index: 1;
}

.v-list-item:hover {
  background-color: #ececec;
}

.v-menu__content {
  background-color: #fff;
}
</style>
